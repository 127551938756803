.container > .founders {
  padding-top: 100px;
  height: 90vh;
  text-align: center;
}

.container > .founders h1 {
  margin-top: 5%;
  color: black;
  /* placeholder color */
}

.container > .founders p {
  margin: 5% 5% 7% 5%;
  color: grey;
  /* placeholder color */
}

.gridContainer > .founders {
  padding-left: 12%;
  padding-right: 12%;
}

.founder {
  height: 100%;
  text-align: center;
}

.founderPicture {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border: 2px solid #bebebe;
}

.nationality {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 0;
}

/* .founder p {
  margin-bottom: 2%;
} */

#founderName {
  margin-top: 5%;
  margin-bottom: 0%;
  font-weight: 600;
}

#affiliation {
  margin-top: 1%;
}

#searchFounders {
  width: 40%;
  padding: 1.2%;
  font-size: 1.2rem;
  float: left;
  margin-left: 10%;
  margin-bottom: 5%;
  border: 1px solid grey;
}
