.loadMoreButtonDiv {
    text-align: center;
}

.loadMoreButtonDiv .addPlayListBox {
    width: 100%;
}

.loadMoreButtonDiv .saveBtnRow {
    justify-content: center;
}