.saveBtnRow .success {
    color: #00B546;
}

.saveBtnRow .danger {
    color: red !important;
}

.shareButton{
    flex: 0 0 40px !important;
}

.shareButton img {
    width: 15px;
}