@media screen and (max-width: 1199px) {
    .playListSection {
        padding: 50px 0 20px;
    }
    .bannerTextWrap {
        padding-right: 60px;
        max-width: 600px;
    }
    .sortByCardColRight {
        flex: 0 0 270px;
    }
    .changePlayListItem .changeplayListName p {
        font-size: 13px;
    }
    .changePlayListItem .changeplayListName {
        padding-left: 10px;
    }
    .sortBoxHead .sleepCardHead h2 {
        font-size: 24px;
    }
    .sleepTimeDetails {
        flex: 0 0 350px;
    }
}
@media screen and (max-width: 991px) {
    .logo {
        padding: 21px 15px 21px 15px;
    }
    .menuRow > ul {
        padding: 15px 20px 21px 15px;
    }
    .menuRow > ul > li {
        margin-left: 30px;
    }
    .userProfile {
        padding: 27px 15px 27px 15px;
    }

    .bannerTextWrap h1 {
        font-size: 40px;
        line-height: 50px;
    }
    .bannerTextWrap p {
        font-size: 22px;
        line-height: 26px;
    }
    .playListSection {
        padding: 40px 0 20px;
    }
    .banner {
        height: 350px;
    }

    .bannerTextWrap {
        padding-right: 40px;
        max-width: 500px;
    }
    .sortBoxHead {
        flex-direction: column;
    }
    .sleepTimeDetails {
        flex: 1 1 auto;
        border-top: 1px solid #f4f4f4;
    }
    .sortByBtnList .btn {
        padding: 5px 15px;
        font-size: 14px;
        height: 35px;
        line-height: 1;
    }
    .sortByCardColLeft {
        padding-right: 15px;
    }
}

@media screen and (max-width: 767px) {
    .logo {
        order: 1;
        padding: 10px 15px;
        width: 180px;
    }
    .menuRow {
        order: 3;
        display: flex;
        align-items: center;
        align-self: stretch;
    }
    .userProfile {
        display: flex;
        order: 2;
        border-right: 2px solid #e5edf3;
        border-left: 0px;
        padding: 15px;
    }
    .banner::after {
        top: 90px;
    }
    .mobileMenuBtn {
        display: block;
        padding: 15px;
    }
    .sectionTitle h2 {
        font-size: 24px;
        line-height: 28px;
    }
    .sortByTitle {
        padding-bottom: 20px;
    }
    .sortByTitle h1 {
        font-size: 24px;
        line-height: 26px;
    }
    .profileDropdown {
        right: 20px;
    }
    .userProfile a .downArrow,
    .userProfile a .userName {
        display: none;
    }
    .mobileMenuBtn button {
        height: 30px;
        outline: 0px;
        width: 30px;
        background: transparent;
        border: 0px;
        position: relative;
    }
    .mobileMenuBtn button img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: rotate(0deg);
        bottom: 0;
        transition: all 0.4s ease-in-out;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .mobileMenuBtn button.open .menuIcon {
        opacity: 0;
        transform: rotate(45deg);
    }
    .mobileMenuBtn button .closeIcon {
        opacity: 0;
        transform: rotate(45deg);
    }
    .mobileMenuBtn button.open .closeIcon {
        opacity: 1;
        transform: rotate(0deg);
    }
    .menuLinkRow {
        position: absolute;
        top: 64px;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.3s ease-out;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100vh - 64px);
        background: linear-gradient(180deg, #fe7652 0%, #e95933 100%);
        z-index: 999;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .menuRow > ul {
        padding: 50px 40px;
    }
    .menuLinkRow.open {
        transform: scaleY(1);
    }
    .menuRow > ul > li {
        margin-left: 0px;
        margin-bottom: 15px;
        width: 100%;
    }
    .menuRow ul > li > a {
        color: #fff;
    }
    .menuRow ul > li > a:hover {
        color: #fff;
    }
    .menuRow ul > li > a .downArrow {
        display: none;
    }
    .menuRow ul > li .subMenu {
        transform: scaleY(1);
        position: static;
        background: transparent;
        box-shadow: none;
        margin: 15px 0;
        padding: 0px;
        width: 100%;
    }
    .menuRow ul > li .subMenu:before {
        display: none;
    }
    .menuRow ul > li .subMenu li a {
        color: #fff;
        padding-left: 25px;
        position: relative;
    }
    .menuRow ul > li .subMenu li a::after {
        content: '_';
        position: absolute;
        top: -12px;
        left: 1px;
        font-size: 26px;
        color: #fff;
        font-weight: 400;
    }
    .hmBanner .bannerTextWrap,
    .bannerTextWrap {
        max-width: 100%;
        padding: 64px 15px 0;
        text-align: center;
        margin: 0 auto;
    }
    .bannerTextWrap h1 {
        font-size: 30px;
        line-height: 40px;
    }
    .bannerTextWrap p {
        font-size: 18px;
        line-height: 24px;
    }
    .bannerBtn {
        padding-top: 10px;
        justify-content: center;
    }
    .bannerBtn .loginBtn {
        margin: 0px 10px;
    }
    .bannerBtn .playBtn {
        margin: 0 10px;
    }
    .playListSection {
        padding: 30px 0;
    }
    .playListRow .playListItem {
        flex: 0 1 50%;
    }
    .playListCard .playListThumb {
        height: 200px;
    }
    .playListCard .playListDetails {
        flex-direction: column;
    }
    .playListCard .playListDetails .playListInfo p {
        margin-bottom: 10px;
    }
    .playListCard .playListDetails .playListInfo h2 {
        font-size: 16px;
    }
    .sortbycontentSec {
        padding-top: 64px;
    }
    .sortbySec {
        padding: 15px;
    }
    .sortByCardRow {
        flex-direction: column;
    }
    .sortByCardColLeft {
        order: 2;
        padding-right: 0px;
        margin-bottom: 80px;
    }

    .sortByCardColLeft .sortByCard {
        margin-bottom: 15px;
    }
    .sortByCardColLeft .sortByCard:last-child {
        margin-bottom: 0px;
    }
    .xsHide {
        display: none;
    }
    .xsShow {
        display: block;
    }
    .addPlayListRow {
        padding: 15px;
    }
    .addPlayListRow .saveBtn {
        height: 30px;
        width: 80px;
        font-size: 14px;
    }
    .videoModal .modalBody {
        width: 300px;
        height: 166px;
    }

    .sortByCardColRight {
        order: 1;
        flex: 1 1 auto;
        position: fixed;
        top: calc(100% - 88px);
        width: 100%;
        height: calc(100vh - 64px);
        z-index: 99;
        left: 0;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
    }
    .sortByCardColRight.open {
        top: 64px;
    }
    .mobileDragHendle {
        display: block;
        width: 100%;
        text-align: center;
        padding: 12px 0 0;
        flex: 0 0 12px;
    }
    .sortByCardColRight .sortByCard {
        background: #fafafa;
        box-shadow: 0px 10px 20px #eff2f7;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .changePlayList .changePlayListItem {
        background: #fafafa;
    }
    .mobileDragHendle .dragHendle {
        background-color: #d8d8d8;
        display: block;
        width: 81px;
        margin: 0 auto;
        height: 5px;
        border-radius: 2.5px;
    }
    .changeplayListRow {
        flex: 1 1 auto;
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
    }
    .changeplayListRow .playListHead {
        flex: 0 0 70px;
    }
    .changeplayListRow .changePlayList {
        flex: 1 1 auto;
        overflow: auto;
    }
    .sortBoxHead .sleepCardHead {
        padding: 15px 15px 10px;
    }
    .sortByBtnList {
        padding: 20px 15px 20px;
    }
    .charteBox {
        padding: 0 15px 15px;
    }
    .chartFillterRow {
        flex-direction: column;
    }
    .chartFillterRight {
        margin-top: 10px;
        display: flex;
    }
}
