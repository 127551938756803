/* Hide play button until we have a promo or how to video */

.bannerBtn > .playBtn {
	display: none;
}

.bannerBtn > .loginBtn {
	margin-left: 0px;
}

/* end hide play button */


/*
Style login header
.bannerTextWrap > h1 {
	letter-spacing: 0.05em;
}
*/